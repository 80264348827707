// from http://localhost:8000/usage/shadowing
import React from 'react';

// Path to the logo file on your project
import rocketseatLogo from '/static/effective-iam-for-aws.png';

const Logo = () => (
  <img src={rocketseatLogo} alt="Effective IAM" style={{ width: 200 }} />
);

export default Logo;